<template>
  <BaseModal
    :value="value"
    scrollable
    stretch
    no-close
    footer-align="none"
    :header-text="modalTitle"
  >
    <div>
      <ui5-panel
        v-for="(section, secIndex) in visibleSections(currentVisibleTab)"
        :key="secIndex"
        width="100%"
        accessible-role="Complementary"
        :header-text="section.sectionHeader"
        :collapsed="section.collapsed"
      >
        <b-row style="width: 100%; margin: auto">
          <b-col
            v-for="(field, fieldIndex) in visibleFields(section)"
            :key="fieldIndex"
            :cols="field.fieldType !== 'WorkingHoursSelector' ? 4 : 12"
          >
            <component
              :is="field.fieldType"
              v-if="!isRefundReason(field.name)"
              ref="inputField"
              :value="formData[field.name]"
              v-bind="field"
              :linked-values="getLinkedValues(field)"
              @input="updateForm(field.name, $event)"
              @change="(fieldName, val) => updateForm(fieldName, val)"
            />

            <component
              :is="field.fieldType"
              v-else-if="isRefundReason(field.name)"
              ref="inputField"
              :value="formData[field.name]"
              v-bind="field"
              @input="updateRefundForm(field, $event)"
            />
          </b-col>
        </b-row>
      </ui5-panel>
    </div>

    <template #footer-buttons>
      <b-row class="py-2" align-v="center">
        <b-col cols="4">
          <div class="text-left">
            <ui5-textarea
              id="ownCommentInput"
              class="ma-1"
              placeholder="Add Comment"
              :value="ownCommentInput"
              @input="(val) => (ownCommentInput = val.target.value)"
            />
          </div>
          <div class="text-left">
            <ui5-checkbox
              id="signOffInput"
              text="Sign Off Form"
              class="ma-1"
              :checked="signOffChecked"
              :readonly="signOffReadonly"
              @change="(val) => (signOffChecked = val.target.checked)"
            />
          </div>
        </b-col>
        <b-col cols="4" class="text-center">
          <ProgressIndicator
            :num-steps="numTabs"
            :current-step="currentTab"
            @prev="nextPrev(-1)"
            @next="nextPrev(+1)"
          />
        </b-col>
        <b-col cols="4">
          <ui5-button
            id="submitBtn"
            design="Emphasized"
            :disabled="submitDisabled"
            class="footer-button"
            @click="handleSubmit"
          >
            Submit
          </ui5-button>
          <ui5-button
            id="saveBtn"
            design="Emphasized"
            class="footer-button"
            @click="handleSave"
          >
            Save
          </ui5-button>
          <ui5-button
            design="Negative"
            class="footer-button"
            @click="handleCancel"
          >
            Cancel
          </ui5-button>
        </b-col>
      </b-row>
    </template>
  </BaseModal>
</template>

<script>
import api from '../api';
import BaseModal from '../components/common/BaseModal.vue';
import ProgressIndicator from '../components/common/ProgressIndicator.vue';
import BlankSpace from '../components/BlankSpace.vue';
import WorkingHoursSelector from '../components/WorkingHoursSelector.vue';

export default {
  name: 'CaseModal',
  components: {
    BaseModal,
    ProgressIndicator,
    BlankSpace,
    WorkingHoursSelector,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    schema: {
      type: Array,
      default: () => [],
    },
    formData: {
      type: Object,
      default: () => {},
    },
    reimbursementCase: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      modalTitle: '',
      oldFormData: {},
      changedFields: {},
      currentTab: 0,
      tabsFinished: 0,
      signOffChecked: true,
      signOffReadonly: false,
      submitDisabled: false,
      ownCommentInput: '',
    };
  },

  computed: {
    visibleTabs() {
      return this.schema.filter((o) => {
        return !o.hidden;
      });
    },
    currentVisibleTab() {
      return this.visibleTabs[this.currentTab];
    },
    numTabs() {
      return this.visibleTabs.length;
    },
  },
  watch: {
    reimbursementCase(newVal) {
      if (newVal) {
        this.setReimbursementCaseData();
      }
    },
  },
  mounted() {
    this.setReimbursementCaseData();
  },
  methods: {
    visibleSections: function (tab) {
      if (!tab) {
        return [];
      }

      return tab.tabContents.filter((o) => {
        return !o.hidden;
      });
    },
    visibleFields: function (section) {
      if (!section) {
        return [];
      }

      return section.sectionContents.filter((o) => {
        if (
          o.dependingElementsMap != null &&
          Object.entries(o.dependingElementsMap).length > 0 &&
          !this.dependingElementsMatch(o.dependingElementsMap)
        ) {
          return false;
        }

        return o.fieldType !== 'HiddenInput';
      });
    },
    dependingElementsMatch: function (dependingElementsMap) {
      return Object.entries(dependingElementsMap).every(
        ([dependingElementName, dependingElementValue]) => {
          const dependingElement = this.formData[dependingElementName];
          if (!dependingElement) {
            return true;
          }
          return dependingElement === dependingElementValue;
        }
      );
    },
    getLinkedValues(field) {
      return [...field.linkedElements].reduce((map, linkedElementName) => {
        if (linkedElementName === 'workingTime') {
          const workingTime = this.getWorkTimeDataMap();
          map['workingTime'] = workingTime;
        } else {
          map[linkedElementName] = this.formData[linkedElementName];
        }
        return map;
      }, {});
    },
    getWorkTimeDataMap() {
      const maxNumWeeks = 7;
      const numDays = 7;
      const result = {};
      for (let weekIdx = 0; weekIdx < maxNumWeeks; weekIdx++) {
        for (let dayIdx = 0; dayIdx < numDays; dayIdx++) {
          const hours =
            this.formData[`workingTime-week${weekIdx}-day${dayIdx}-hours`];
          if (hours) {
            result[`workingTime-week${weekIdx}-day${dayIdx}-hours`] = hours;
          }
          const minutes =
            this.formData[`workingTime-week${weekIdx}-day${dayIdx}-minutes`];
          if (minutes) {
            result[`workingTime-week${weekIdx}-day${dayIdx}-minutes`] = minutes;
          }
        }
      }
      return result;
    },
    // Modal
    setReimbursementCaseData() {
      if (!this.reimbursementCase) {
        return;
      }

      this.oldFormData = this.formData;
      this.setCaseState();
      this.setCommentFromCase();
      this.setSignedOffFromCase();
      this.setDisabledState();
      this.resetStepIndicator();
      this.setModalTitle();

      this.showTab(this.currentTab);
      this.$emit('input', true);
    },
    hide() {
      this.$emit('input', false);
    },
    setCaseState() {
      if (this.readonly) {
        const state = this.reimbursementCase.state;
        const signedOffStates = ['Signed off', 'Sent', 'Response received'];
        // this.signOffChecked = signedOffStates.includes(state);
      } else {
        // this.signOffChecked = true;
      }
    },
    setCommentFromCase() {
      this.ownCommentInput = this.reimbursementCase?.ownComment || '';
    },
    setSignedOffFromCase() {
      this.signOffChecked = this.reimbursementCase?.signedOff || false;
    },
    setCommentFromInput() {
      const rCase = this.reimbursementCase;
      rCase.ownComment = this.ownCommentInput;
      this.$emit('set-reimbursement-case', rCase);
    },
    setSignedOffFromInput() {
      const rCase = this.reimbursementCase;
      rCase.signedOff = this.signOffChecked;
      this.$emit('set-reimbursement-case', rCase);
    },
    setDisabledState() {
      this.submitDisabled = this.reimbursementCase.readonly;
      this.signOffReadonly = this.reimbursementCase.readonly;
    },
    setModalTitle() {
      this.modalTitle =
        this.reimbursementCase.formCode +
        ' - ' +
        this.reimbursementCase.caseDetails.additionalInfo.firstName +
        ' ' +
        this.reimbursementCase.caseDetails.additionalInfo.lastName +
        ' - (' +
        this.reimbursementCase.caseDetails.additionalInfo.employeeId +
        ')';
      if (this.reimbursementCase.startDate != null) {
        this.modalTitle +=
          ' from ' +
          this.reimbursementCase.startDate +
          ' to ' +
          this.reimbursementCase.endDate;
      }
    },
    resetStepIndicator() {
      this.currentTab = 0;
    },
    // Modal Tabs
    showTab(currentTab) {
      if (currentTab === this.numTabs - 1) {
        if (!this.reimbursementCase.readonly) {
          this.submitDisabled = false;
        }
      } else {
        if (!this.reimbursementCase.readonly) {
          this.submitDisabled = true;
        }
      }
    },
    nextPrev(stepIncrease) {
      const valid = this.validateForm();
      if (stepIncrease === 1 && !valid) {
        return;
      }
      if (valid) {
        this.tabsFinished += 1;
      }
      this.currentTab = this.currentTab + stepIncrease;
      this.showTab(this.currentTab);
    },
    updateForm(fieldName, value) {
      if (!this.changedFields[fieldName]) {
        this.changedFields[fieldName] = this.oldFormData[fieldName];
      }
      const newFormData = this.formData;
      this.$set(newFormData, fieldName, value);
      this.$emit('update-form', this.formData);
    },
    async updateRefundForm(field, value) {
      console.warn(field);
      const fieldName = field.name;
      if (!this.changedFields[fieldName]) {
        this.changedFields[fieldName] = this.oldFormData[fieldName];
      }
      if (fieldName.includes('refundReasonText')) {
        const formType = this.reimbursementCase.formCode;
        await api
          .getRefundReasons(formType)
          .then(async (response) => {
            let refundReasonCode = null;
            try {
              refundReasonCode = response.data.filter(
                (o) => o.refundText === value
              )[0].refundCode;
            } catch (error) {
              await this.$store.dispatch(
                'showToastMessage',
                'Failed to find refund code for refund text ' + value
              );
              return;
            }
            if (refundReasonCode !== null) {
              refundReasonCode = refundReasonCode.toString();
            }
            const newFormData = this.formData;
            newFormData['refundReasonCode'] = refundReasonCode;
            this.$set(newFormData, fieldName, value);
            this.$set(newFormData, 'refundReasonCode', refundReasonCode);
            this.$emit('update-form', this.formData);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    // Modal Buttons
    handleSave(e) {
      e.preventDefault();
      if (!this.validateForm()) {
        return;
      }
      this.setCommentFromInput();
      this.setSignedOffFromInput();
      this.$nextTick(() => {
        const formData = this.formData;
        formData['changedFields'] = JSON.stringify(this.changedFields);
        this.$emit('set-form-data', formData);
        this.$emit('save', formData, this.reimbursementCase);
        this.closeDialog();
      });
    },
    handleSubmit() {
      if (!this.validateForm()) {
        return;
      }
      this.setCommentFromInput();
      this.setSignedOffFromInput();
      this.$nextTick(() => {
        if (this.signOffChecked) {
          this.$emit('signAndSubmit', this.formData, this.reimbursementCase);
        } else {
          this.$emit('submit', this.formData, this.reimbursementCase);
        }
        this.closeDialog();
      });
    },
    handleCancel() {
      this.$emit('reset-data', this.oldFormData);
      this.closeDialog();
    },
    closeDialog() {
      this.currentTab = 0;
      this.tabsFinished = 0;
      this.hide();
    },
    validateForm() {
      const inputs = this.$refs.inputField || [];

      const isInvalid = inputs.some((input) => !input.isValid);

      return !isInvalid;
    },
    isRefundReason(name) {
      return name.includes('refundReason');
    },
  },
};
</script>

<style scoped>
.footer-button {
  width: 6rem;
}
</style>

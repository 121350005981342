<template>
  <div class="app-bar">
    <nav id="navbar">
      <div id="nav-header" @click="toHome">
        <img id="logo" :src="logo" alt="DK Reimbursement Logo" />
        DK Reimbursement Solution
      </div>

      <div id="nav-env-container">
        <span v-if="fetched" id="nav-environment-text">
          {{ env }}
        </span>
      </div>

      <div id="nav-links">
        <div v-if="loggedIn">
          <ui5-button
            :class="{ active: activeLink === 'home' }"
            @click="toHome"
          >
            Home
          </ui5-button>
          <ui5-button
            :class="{ active: activeLink === 'config' }"
            @click="toConfig"
          >
            Config
          </ui5-button>
          <ui5-button
            :class="{ active: activeLink === 'reports' }"
            @click="toReports"
          >
            Reports
          </ui5-button>
          <ui5-button
            :class="{ active: activeLink === 'finance' }"
            @click="toFinance"
          >
            Finance
          </ui5-button>
          <ui5-button
            :class="{ active: activeLink === 'timeReport' }"
            @click="totimeReport"
          >
            Time Report
          </ui5-button>
          <ui5-button @click="signout"> Sign out </ui5-button>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import logo from '../assets/epiuse.png';
import profile from '../assets/profile.png';
import api from '../api/index';
import { dkConstants } from '../dk-constants';
import { mapState } from 'vuex';

export default {
  data: function () {
    return {
      logo: logo,
      profile: profile,
      activeLink: 'home',
      fetched: false,
      env: '',
    };
  },

  computed: {
    ...mapState({
      loggedIn: 'loggedIn',
    }),
  },

  created() {
    this.activeLink = this.getActiveLink();

    api.getActiveProfile().then((response) => {
      const env = response.data.activeProfile;
      if (!env.endsWith(dkConstants.DK_ENVIRONMENT_SUFFIXS.PRODUCTION)) {
        this.env = 'Env: ' + env.charAt(0).toUpperCase() + env.slice(1);
      } else {
        this.env = '';
      }
      this.fetched = true;
    });
  },

  methods: {
    toHome() {
      const path = `/home`;
      this.activeLink = 'home';
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    },

    toConfig() {
      const path = `/config`;
      this.activeLink = path.slice(1, path.length);
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    },

    toReports() {
      const path = `/reports`;
      this.activeLink = path.slice(1, path.length);
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    },

    toFinance() {
      const path = `/finance`;
      this.activeLink = path.slice(1, path.length);
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    },

    totimeReport() {
      const path = `/timeReport`;
      this.activeLink = path.slice(1, path.length);
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    },

    getActiveLink() {
      const path = this.$route.path;
      if (path === '/' || path.length <= 1) {
        return '';
      }
      return path.slice(1, path.length);
    },

    async signout() {
      api
        .logout()
        .then((response) => {
          if (response.status === 200) {
            this.$store.dispatch('logout');
            this.$router.push('/');
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style scoped>
#logo {
  width: 100px;
}
#navbar {
  height: 40px;
  background: #354a5f;
  display: flex;
  justify-content: space-between;
  padding: 4px 16px;
}
#nav-header {
  font-weight: bold;
  color: #fff;
  cursor: pointer;
}
#nav-environment-text {
  color: #fff;
  display: block;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 0 5px #444;
  pointer-events: none;
}
#nav-env-container {
  height: 100%;
  padding-bottom: 1rem;
}
#nav-links ui5-button {
  height: 32px;
  color: #fff !important;
  padding-left: 8px;
  padding-right: 8px;
  transition: 0.4s ease;
  background: #354a5f;
  border: none;
}
#nav-environment-text {
  color: #fff;
  display: block;
  font-weight: 400;
  margin: 1px 0;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 0 5px #444;
  pointer-events: none;
  padding-bottom: 1rem;
}
#nav-links ui5-button:hover,
#nav-links ui5-button:active,
#nav-links ui5-button.active {
  background: #283848;
}
</style>
